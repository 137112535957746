import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./components/App";
import { loadConfig } from "./utils/config";
import { Auth0Provider } from '@auth0/auth0-react';

loadConfig().then(() => {
    const container = document.getElementById("app");
    const root = ReactDOM.createRoot(container!);
    root.render(
        <Auth0Provider
            domain="glimmerai.eu.auth0.com"
            clientId="sQKfhV15tR3hLygT1EmSLGNsh5QKeXs9"
            authorizationParams={{
                redirect_uri: window.location.origin
            }}
        >
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </Auth0Provider>
    );
});
