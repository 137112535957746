import {
  Badge,
  Button,
  Center,
  Container,
  // Group,
  SimpleGrid,
  Text,
  ThemeIcon,
} from "@mantine/core";
import {
  // IconCloudDownload,
  IconCurrencyDollar,
  // IconKey,
  IconPlus,
  IconLock,
  IconNorthStar,
} from "@tabler/icons-react";
// import { useLiveQuery } from "dexie-react-hooks";
// import { Logo } from "../components/Logo";
// import { SettingsModal } from "../components/SettingsModal";
import { db } from "../db";
// import { config } from "../utils/config";
import { useNavigate } from "@tanstack/react-location";
import { nanoid } from "nanoid";

export function IndexRoute() {
  // const settings = useLiveQuery(() => db.settings.get("general"));
  // const { openAiApiKey } = settings ?? {};
  const navigate = useNavigate();

  return (
    <>
      <Center py="xl" sx={{ height: "100%" }}>
        <Container size="sm">
          <div style={{ marginBottom: '10px', display: 'flex', gap: '10px' }}>
            <Badge>GPT-4</Badge>
            <Badge color="teal">Chat Bison</Badge>
            <Badge color="grape">Meta Llama</Badge>
          </div>
          <Text>
            {/* <Logo style={{ maxWidth: 240 }} /> */}
            <img src="/glimmer_dark_1071x201.svg" alt="" style={{ width: '240px' }} />
          </Text>
          <Text mt={4} size="xl">
            AI chat with many models, personas and scenarios
          </Text>
          <SimpleGrid
            mt={50}
            cols={3}
            spacing={30}
            breakpoints={[{ maxWidth: "md", cols: 1 }]}
          >
            {features.map((feature) => (
              <div key={feature.title}>
                <ThemeIcon variant="outline" size={50} radius={50}>
                  <feature.icon size={26} stroke={1.5} />
                </ThemeIcon>
                <Text mt="sm" mb={7}>
                  {feature.title}
                </Text>
                <Text size="sm" color="dimmed" sx={{ lineHeight: 1.6 }}>
                  {feature.description}
                </Text>
              </div>
            ))}
          </SimpleGrid>
          <Button
            mt={20}
            w={200}
            leftIcon={<IconPlus size={20} />}
            onClick={() => {
              const id = nanoid();
              db.chats.add({
                id,
                description: "New Chat",
                totalTokens: 0,
                createdAt: new Date(),
              });
              navigate({ to: `/chats/${id}` });
            }}
          >
            New Chat
          </Button>
          {/* <Group mt={50}>
            {config.allowSettingsModal && (
              <SettingsModal>
                <Button
                  size="md"
                  variant={openAiApiKey ? "light" : "filled"}
                  leftIcon={<IconKey size={20} />}
                >
                  {openAiApiKey ? "Change OpenAI Key" : "Enter OpenAI Key"}
                </Button>
              </SettingsModal>
            )}
            {config.showDownloadLink && !window.todesktop && (
              <Button
                component="a"
                href="https://dl.todesktop.com/230313oyppkw40a"
                // href="https://download.chatpad.ai/"
                size="md"
                variant="outline"
                leftIcon={<IconCloudDownload size={20} />}
              >
                Download Desktop App
              </Button>
            )}
          </Group> */}
        </Container>
      </Center>
    </>
  );
}

const features = [
  {
    icon: IconCurrencyDollar,
    title: "Free and open source",
    description:
      "This app is provided for free and the source code is available on GitHub.",
  },
  {
    icon: IconLock,
    title: "Privacy focused",
    description:
      "No tracking, no cookies, no bullshit. All your data is stored locally.",
  },
  {
    icon: IconNorthStar,
    title: "Best experience",
    description:
      "Crafted with love and care to provide the best experience possible.",
  },
];
